/**
 * Flag to indicate if menu is visible (mobile only)
 */
var menuVisible = false;
/**
 * Toggle the mobile menu visible/invisible
 */
function toggleMenu() {
    /* toggle the open/closed value */
    menuVisible = !menuVisible;
    /* build the list of class names */
    var classList = 'menu menu-' + (menuVisible ? 'open' : 'closed');
    /* apply the class list */
    var menu = document.getElementById('menu');
    menu.setAttribute('class', classList);
}
/**
 * Apply the input styling
 * @param id
 * @param event
 */
function applyInputStyle(id, event) {
    var input = document.getElementById(id + '-text');
    var text = '';
    if (input instanceof HTMLInputElement || input instanceof HTMLTextAreaElement)
        text = input.value;
    /* determine which classes to apply */
    var classList = 'form-input-label-empty';
    if (text.length > 0 || event.type === 'focus')
        classList += ' form-input-label';
    /* apply the classes */
    var label = document.getElementById(id + '-label');
    if (label)
        label.setAttribute('class', classList);
}
/**
 * Update the form with contact information
 * @param event
 */
function changeContactMethod(event) {
    var method = event.target.value;
    var unMethod = method === 'phone' ? 'email' : 'phone';
    document.getElementById('input-' + method).setAttribute('class', 'input-label');
    document.getElementById('input-' + unMethod).setAttribute('class', 'input-label hidden');
}
/**
 * Send a message via the API
 */
function sendMessage() {
    /* get button/spinner elements and class lists to apply */
    var button = document.getElementById('send_button');
    var busy = document.getElementById('send_busy');
    var visible = 'button';
    var hidden = visible + ' hidden';
    /* start the spinner and disable the button */
    button.setAttribute('class', hidden);
    busy.setAttribute('class', visible);
    try {
        var inputContactPrefEmail = document.getElementById('input-radio-email').checked;
        var inputContactPrefPhone = document.getElementById('input-radio-phone').checked;
        var inputEmailText = document.getElementById('input-email-text').value;
        var inputPhoneText = document.getElementById('input-phone-text').value;
        var inputMessageText = document.getElementById('input-message-text').value;
        /* construct the request message */
        var req = {
            'contact_method': inputContactPrefPhone ? 'phone' : 'email',
            'message': inputMessageText
        };
        if (inputContactPrefPhone)
            req['phone'] = inputPhoneText;
        if (inputContactPrefEmail)
            req['email'] = inputEmailText;
        var post = new XMLHttpRequest();
        post.open('POST', 'https://api.goodbots.me/v1/action', false);
        post.setRequestHeader('Content-Type', 'application/json');
        post.send(JSON.stringify(req));
        var res = JSON.parse(post.responseText);
        /* clear the message if it was sent */
        if (res['ok']) {
            document.getElementById('input-email-text').value = '';
            document.getElementById('input-phone-text').value = '';
            document.getElementById('input-message-text').value = '';
        }
        /* otherwise, report an error */
        else {
            alert('Failed to send message.  Try again.');
        }
    }
    catch (e) {
        console.log(e);
    }
    finally {
        /* hide the spinner and enable the button */
        setTimeout(button.setAttribute.bind(button), 1000, 'class', visible);
        setTimeout(busy.setAttribute.bind(busy), 1000, 'class', hidden);
    }
}
/**
 *
 */
function init() {
    applyInputStyle('input-email', { 'type': 'init' });
    applyInputStyle('input-message', { 'type': 'init' });
}
init();
